import React, { useEffect } from "react";
import { IndexPageContainer } from ".";
import Cookies from "cookies-js";
import SEO from "@src/components/SEO";

export default function IndexPage({ pageContext }) {
  useEffect(() => {
    Cookies.set("web_external_homepage_experiment", "control", {
      expires: Infinity,
      domain: "classdojo.com",
    });
  }, []);

  return (
    <>
      <SEO noindex={true} />
      <IndexPageContainer pageContext={pageContext} inHomepageExperiment="control1" />;
    </>
  );
}
